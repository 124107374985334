import React from 'react';

type PhoneProps = {
  phone: string;
};

const Phone = ({ phone }: PhoneProps) => (
  <a href={`tel:+48${phone.replace(/ /g, '')}`} className="phone-link">
    {phone}
  </a>
);

export default Phone;
