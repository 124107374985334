import React from 'react';

type EmailProps = {
  email: string;
};

const Email = ({ email }: EmailProps) => (
  <a href={`mailto:+${email}`} className="phone-link">
    {email}
  </a>
);

export default Email;
