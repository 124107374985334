import React, { useState, useEffect } from 'react';
import Logo from '../images/logo.inline.svg';
import cn from 'classnames';
import MenuMobile, { MenuMobileProps } from './menuMobile';
import MenuLinks from './menuLinks';
import ScrollIntoView from 'react-scroll-into-view';
import Phone from './phone';

const debounce = require('lodash.debounce');

const Header = ({ isMobileMenuOpen, setIsMobileMenuOpen }: MenuMobileProps) => {
  const [isSticky, setIsSticky] = useState(false);

  const stickyNavigation = () => setIsSticky(window.scrollY >= 10);

  useEffect(() => {
    stickyNavigation();
    window.addEventListener('scroll', debounce(stickyNavigation));
    return () => {
      window.removeEventListener('scroll', stickyNavigation);
    };
  }, []);

  return (
    <>
      <MenuMobile
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <header className={cn('headerHolder', { 'nav-fixed-top': isSticky })}>
        <div className="nav-button-holder">
          <div className="nav-button" onClick={() => setIsMobileMenuOpen(true)}>
            <span className="icon-bar"></span>
          </div>
        </div>
        <div className="logo">
          <ScrollIntoView selector="#top" alignToTop={true}>
            <a
              href="#top"
              aria-label="Pizzeria u Marioli"
              className="logo-img"
              onClick={(e) => e.preventDefault()}
            >
              <Logo />
            </a>
          </ScrollIntoView>
        </div>
        <nav className="nav-holder">
          <ul className="menu-nav">
            <MenuLinks />
          </ul>
        </nav>
        <div className="btn-header">
          <Phone phone="22 725 49 69" /> lub <Phone phone="570 539 657" />{' '}
          <br />
          niedz. - czw. 10.00-21.00 <br /> pt. - sob. 10.00-22.00
        </div>
      </header>
    </>
  );
};

export default Header;
