import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

type SeoDataType = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
};

type SeoType = {
  mobileMenuOpen: boolean;
};

const Seo = ({ mobileMenuOpen }: SeoType) => {
  const { site }: SeoDataType = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = site.siteMetadata.description;
  const title = site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl-PL',
      }}
      bodyAttributes={{
        id: 'top',
        class: mobileMenuOpen ? 'has-active-menu' : '',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
      ]}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Restaurant",
          "name": "Pizzeria u Marioli",
          "description": "Od 20 lat przygotowujemy dla Was wyśmienitą pizzę ze świeżych składników. Dostawa w Błoniu i okolicach. W ofercie restauracji pizza, makarony, zupy, sałatki i desery.",
          "image": [
            "https://pizzeriaumarioli.pl/1x1.jpg",
            "https://pizzeriaumarioli.pl/4x3.jpg",
            "https://pizzeriaumarioli.pl/16x9.jpg"
          ],
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Sochaczewska 36",
            "addressLocality": "Błonie",
            "addressRegion": "Mazowieckie",
            "postalCode": "05-870",
            "addressCountry": "PL"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.2",
            "ratingCount": "366"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 52.19916,
            "longitude": 20.60849
          },
          "url": "https://pizzeriaumarioli.pl/",
          "telephone": "+48227254969",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Sunday"
              ],
              "opens": "10:00",
              "closes": "21:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Friday",
                "Saturday"
              ],
              "opens": "10:00",
              "closes": "22:00"
            }
          ],
          "menu": "https://pizzeriaumarioli.pl/",
          "acceptsReservations": "True",
          "servesCuisine": "Kuchania włoska",
          "priceRange": "$"
        }
      `}
      </script>
    </Helmet>
  );
};

export default Seo;
