import React, { Suspense, useEffect, useState } from 'react';
import Header from '../components/header';
import SectionHome from '../components/sectionHome';
import Seo from '../components/seo';

const SectionMenu = React.lazy(() => import('../components/sectionMenu'));
const SectionDelivery = React.lazy(
  () => import('../components/sectionDelivery')
);
const Footer = React.lazy(() => import('../components/footer'));

const IndexPage = () => {
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    setIsSSR(false);
  }, []);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <Seo mobileMenuOpen={isMobileMenuOpen} />
      <Header
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <SectionHome />
      {!isSSR && (
        <Suspense fallback={<div>Wczytywanie...</div>}>
          <SectionMenu />
          <SectionDelivery />
          <Footer />
        </Suspense>
      )}
    </>
  );
};

export default IndexPage;
