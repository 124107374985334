import cn from 'classnames';
import React from 'react';
import Email from './email';
import MenuLinks from './menuLinks';
import Phone from './phone';

export type MenuMobileProps = {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
};

const MenuMobile = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}: MenuMobileProps) => (
  <>
    <div
      className={cn('menu-mask', { 'is-active': isMobileMenuOpen })}
      onClick={() => setIsMobileMenuOpen(false)}
    ></div>
    <div
      className={cn('mobile-menu-holder', { 'is-active': isMobileMenuOpen })}
    >
      <div className="exit-mobile" onClick={() => setIsMobileMenuOpen(false)}>
        <span className="icon-bar1"></span>
        <span className="icon-bar2"></span>
      </div>
      <ul className="menu-mobile">
        <MenuLinks extraCallbackOnClick={() => setIsMobileMenuOpen(false)} />
      </ul>
      <div className="menu-contact">
        <p>
          Sochaczewska 36
          <br />
          05-870, Błonie
        </p>
        <p className="mobile-phone">
          <Phone phone="22 725 49 69" />
          <br />
          <Phone phone="570 539 657" />
        </p>
        <p>
          <Email email="pizzeria@umarioli.pl" />
        </p>
      </div>
    </div>
  </>
);

export default MenuMobile;
