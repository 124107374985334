import React, { MouseEvent } from 'react';
import ScrollIntoView from 'react-scroll-into-view';

type MenuLinksProps = {
  extraCallbackOnClick?: () => void;
};

const MenuLinks = ({ extraCallbackOnClick }: MenuLinksProps) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    extraCallbackOnClick && extraCallbackOnClick();
  };

  return (
    <>
      <li>
        <ScrollIntoView selector="#top" alignToTop={true}>
          <a href="#top" onClick={handleClick}>
            O nas
          </a>
        </ScrollIntoView>
      </li>
      <li>
        <ScrollIntoView selector="#section-menu" alignToTop={true}>
          <a href="#section-menu" onClick={handleClick}>
            Menu
          </a>
        </ScrollIntoView>
      </li>
      <li>
        <ScrollIntoView selector="#delivery" alignToTop={true}>
          <a href="#delivery" onClick={handleClick}>
            Dostawa
          </a>
        </ScrollIntoView>
      </li>
      <li>
        <ScrollIntoView selector="#footer" alignToTop={true}>
          <a href="#footer" onClick={handleClick}>
            Kontakt
          </a>
        </ScrollIntoView>
      </li>
    </>
  );
};

export default MenuLinks;
