import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import ScrollIntoView from 'react-scroll-into-view';

const SectionHome = () => (
  <section className="home">
    <StaticImage
      src="../images/main.webp"
      className="home-image"
      alt="Pizzeria u Marioli w Błoniu"
      loading="eager"
      layout="fullWidth"
      quality={95}
    />
    <div className="home-caption">
      <h3 className="intro-txt">pizzeria</h3>
      <h1 className="home-info">u Marioli</h1>
      <div>
        Nasza pizza to wyjątkowe i niepowtarzalne ciasto oraz najwyższej jakości
        świeże składniki.
        <br />
        <ScrollIntoView selector="#section-menu" alignToTop={true}>
          <a
            href="#section-menu"
            onClick={(e: React.MouseEvent) => e.preventDefault()}
            className="home-btn"
          >
            Zobacz menu
          </a>
        </ScrollIntoView>
      </div>
    </div>
  </section>
);

export default SectionHome;
